import styles from "./UnderConstruction.module.css";

const UnderConstruction = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles[`under-construction`]}>Under construction</div>
    </div>
  );
};

export default UnderConstruction;
