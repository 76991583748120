import styles from "./App.module.css";
import { Routes, Route, Navigate } from "react-router-dom";
import UnderConstruction from "./UnderConstruction";

function App() {
  return (
    <div className={styles.App}>
      <Routes>
        <Route path="/" element={<UnderConstruction />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
